import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Image, InfoIcon, List, Table, Row, Tabs, Expander, Attributes, WarningIcon } from '../components';
export const authToken = [{
  param: 'GET',
  value: 'https://my.smartvault.com/users/secure/IntegratedApplications.aspx?client_id={DEVELOPER_CLIENT_ID}&response_type=code&redirect_uri={REDIRECT_URI_ENCODED}&state={CLIENT_STATE}'
}];
export const accessToken = [{
  param: 'POST',
  value: '/auto/auth/dtoken/2'
}];
export const refreshToken = [{
  param: 'POST',
  value: '/auto/auth/rtoken/2'
}];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  authToken,
  accessToken,
  refreshToken,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "oauth-20"
    }}>{`OAuth 2.0`}</h1>
    <p>{`OAuth 2.0 is a standard authorization protocol used to grant third-party applications access to their resources on various services without sharing their login credentials.  All requests are done over HTTPS using TLS 1.3. `}</p>
    <br />
Overview of the OAuth 2.0 authentication flow.
    <br />
    <br />
    <Image src={'/oauth2_flow.png'} width="50" alt="OAuth 2.0 authentication flow" title="OAuth 2.0 authentication flow" mdxType="Image" />
    <br />
    <br />
    <p>{`At a high level the OAuth 2.0 framework will require two steps to authorize a client to perform actions on behalf of an end-user.`}</p>
    <ol>
      <li parentName="ol">{`Authorization Token Request`}</li>
      <li parentName="ol">{`Authorization Token Exchange `}</li>
    </ol>
    <p>{`There is also an optional step that can be used to allow for a "frictionless" integration for an end-user.`}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Refreshing an Access Token`}<br />
        <br /></li>
    </ol>
    <h2 {...{
      "id": "authorization-token-request"
    }}>{`Authorization Token Request`}</h2>
    <hr style={{
      "width": "100%"
    }} />
    <p>{`The Authorization Token Request is an HTTP request sent by the client to the authorization server to request an authorization token.
Smartvault’s authorization page can be found at this address. `}<a parentName="p" {...{
        "href": "https://my.smartvault.com/users/secure/IntegratedApplications.aspx"
      }}>{`https://my.smartvault.com/users/secure/IntegratedApplications.aspx`}</a>{`
Typically, the client will formulate this address for the end-user. The end-user will visit this page and allow the client access to perform actions on their behalf.`}</p>

    <Row mdxType="Row">
	<div>
		<p>
			This call is used to request an authorization token.
		</p>
	</div>
	<Table title="Request authorization token" rows={authToken} mdxType="Table" />
    </Row>
    <br />
    <p>{`The following query parameters should be used to fulfill the request:`}</p>
    <Attributes items={[{
      name: 'response_type',
      type: 'string',
      description: 'Required.  This should be set to "code."'
    }, {
      name: 'client_id',
      type: 'string',
      description: 'Required.  This is the client id that the developer account has setup.'
    }, {
      name: 'redirect_uri',
      type: 'string',
      description: 'Required.  This is the redirect uri that the developer account has setup. It must match exactly.'
    }, {
      name: 'state',
      type: 'string',
      description: 'Optional.  This is a recommended parameter that is used to store some state for the “CLIENTS”. It should also be used as a security measure to prevent cross-site request forgery. RFC 6749 - The OAuth 2.0 Authorization Framework (ietf.org).'
    }]} mdxType="Attributes" />
    <br />
    <h2 {...{
      "id": "authorization-token-exchange"
    }}>{`Authorization Token Exchange`}</h2>
    <hr style={{
      "width": "100%"
    }} />
    <p>{`At this step, the client will have received an authorization code. The client will then need to exchange this code for an `}<strong parentName="p">{`access token`}</strong>{` by sending another HTTP request to the authorization server with the authorization code.
The authorization server validates the authorization code and returns an `}<strong parentName="p">{`access token`}</strong>{` if it is valid.`}</p>

    <Row mdxType="Row">
	<div>
		<p>
			This call is used to request an access token.
		</p>
	</div>
	<Table title="Request access token" rows={accessToken} mdxType="Table" />
    </Row>
    <br />
    <p>{`The endpoint’s body is as follows:`}</p>
    <Attributes items={[{
      name: 'grant_type',
      type: 'string',
      description: 'Required.  This should be set to authorization_code.'
    }, {
      name: 'code',
      type: 'string',
      description: 'Required.  Must be the Authorization Code received in the redirect_uri.'
    }, {
      name: 'client_secret',
      type: 'string',
      description: 'Required.  OAuth 2.0 clients will be required to send the client secret as is.  Autonomous clients will use the Authorization Code to create the client secret.'
    }, {
      name: 'client_id',
      type: 'string',
      description: 'Required.  This is the client id that the developer account has setup.'
    }]} mdxType="Attributes" />
    <br />
    <p>{`Example request body:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "grant_type": "authorization_code",
    "code": "sm8LgBeRRkK98pqaj4nwfQ",
    "client_secret": "{CLIENT_SECRET}",
    "client_id": "{CLIENT_ID}"
}
`}</code></pre>
    <br />
    <p>{`Example response body:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "access_token": "4j8nMihehfIdtYtgfsloeeOE7W2aq1",
        "token_type": "bearer",
        "expires_in": 1209600,
        "refresh_token": "iyuU9NRf346YtTdxr57YhpknB8330Hbwsz",
        "refresh_token_expires_in": 2592000,
        "id": "User:bkaTcoKEWkq-d0eyMhmXiw"
    }
}

// Specification Reference: Access Token Successful Response - https://datatracker.ietf.org/doc/html/rfc6749#section-5.1
`}</code></pre>
    <p>{`At this point the client now has an `}<strong parentName="p">{`access_token`}</strong>{` as well as a `}<strong parentName="p">{`refresh_token`}</strong>{`.`}</p>
    <br />
Access tokens are issued to access user's data on a third-party service, and can be used to access those resources until they expire or are revoked. The client should store the access token securely and use it until it is no longer valid to avoid unauthorized access to the protected resources.
The refresh token is also returned with this call. It can be used by the client to obtain a new access token if the current one expires or has been revoked.
    <br />
    <br />
    <h2 {...{
      "id": "refreshing-an-access-token"
    }}>{`Refreshing an Access Token`}</h2>
    <hr style={{
      "width": "100%"
    }} />
    <p>{`Your client will have to handle the case when the Access Token is expired. You will be required to send a POST request to our API and request a new access token. `}</p>

    <Row mdxType="Row">
	<div>
		<p>
			This call is used to request a refresh token.
		</p>
	</div>
	<Table title="Request refresh token" rows={refreshToken} mdxType="Table" />
    </Row>
    <br />
    <p>{`The body MUST include the following:`}</p>
    <Attributes items={[{
      name: 'grant_type',
      type: 'string',
      description: 'Required.  Set to "refresh_token".'
    }, {
      name: 'refresh_token',
      type: 'string',
      description: 'Required.  This must be the token received for the end-user you’re requesting access token for.'
    }, {
      name: 'client_secret',
      type: 'string',
      description: 'Required.  OAuth clients will use the client secret that was provided to them when the initial creation of the client.  Autonomous clients will use the refresh token to create the client secret.  The client_secret here is the signed Refresh Token. SmartVault will validate the signature utilizing the public key provided to smartvault at the creation step of the clientid.'
    }]} mdxType="Attributes" />
    <br />
    <p>{`Example request body:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Content-Type: application/x-www-form-urlencoded
    {
      grant_type="refresh_token"
      client_secret={CLIENT_SECRET}
      refresh_token="tGzv3JOkF0XG5Qx2TlKWIA"
    }
`}</code></pre>
    <br />
    <p>{`Example response body:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "access_token": "REVMzOEXFM36Y+78HniEs39Cdigo",
        "token_type": "bearer",
        "expires_in": 1209600,
        "refresh_token": "FeWifGjTRR+Zokf46tf",
        "refresh_token_expires_in": 2592000,
        "id": "User:bkaTcoKEWkq-d0eyMhmXiw"
    }
}

//Specification Reference: Refresh Token Successful Response - https://datatracker.ietf.org/doc/html/rfc6749#section-5.1
`}</code></pre>
    <br />
    <InfoIcon mdxType="InfoIcon" />
Refresh Tokens are single-use tokens. After usage they must be replaced as they will no longer be valid.
    <br />
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      